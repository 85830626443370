import styled from 'styled-components';
import {commonPalette} from 'shared/styles/palette';

const colors = {
  grey: commonPalette.txtGrey
};

export const DrawerWrapper = styled.div`
  background: #fff;
  position: relative;
  max-width: 90vw;
  width: 500px;
`;

export const DrawerTitle = styled.h2`
  font-size: 24px;
  font-weight: 400;
  line-height: 1.5;
  margin-right: 40px;
`;

export const DrawerSubtitle = styled.h3`
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 16px;
  margin-top: 32px;
`;

export const Paragraph = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #575f5f;
  line-height: 1.5;
  margin-top: 8px;
  text-align: ${props => (props.center ? 'center' : 'left')};
`;

export const CloseButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

export const Callout = styled.div`
  align-items: flex-start;
  background: #ebf2f9;
  border-radius: 8px;
  color: #575f5f;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  gap: 8px;
  line-height: 1.5;
  margin: 16px 0;
  padding: 12px 16px;
`;

export const SkillCard = styled.div`
  align-items: center;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin: 32px 0 8px 0;
  padding: 12px 16px;
`;

export const SkillCardTitle = styled.div`
  font-size: 15px;
  line-height: 20px;
  margin-bottom: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const SkillCardStatus = styled.div`
  font-size: 13px;
  line-height: 16px;
  color: ${colors.grey};
`;

export const StatusCircle = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  ${props => (props.fill === true ? `background: ${props.color};` : `border: solid 1px ${props.color};`)};
`;

export const Card = styled.div`
  background: #f9f9f9;
  border-radius: 2px;
  font-size: 14px;
  padding: 16px;
  margin-top: 32px;
`;

export const CardTitle = styled.h4`
  font-size: 17px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 16px;
`;

export const CardLevel = styled.div`
  align-items: center;
  background: #fff;
  border: 1px solid #ededed;
  display: flex;
  flex-direction: row;
  font-size: 15px;
  gap: 10px;
  padding: 12px;
`;

export const CardLevelContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 90px;
  gap: 15px;
`;

export const CardLevelUnstyled = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 15px;
  gap: 10px;
  margin-bottom: 4px;
`;

export const CardRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const CardSpacer = styled.div`
  display: flex;
  flex-direction: row;
  height: 20px;
`;

export const CardLevelNum = styled.div`
  align-items: center;
  background: #496270;
  border-radius: 3px;
  color: #fff;
  display: inline-flex;
  font-size: 13px;
  height: 20px;
  justify-content: center;
  width: 20px;
  flex-direction: row;
  font-size: 15px;
  gap: 10px;
  padding: 12px;
`;

export const CardTextarea = styled.textarea`
  background: #fff;
  border: 1px solid #ececec;
  border-radius: 3px;
  display: block;
  min-height: 100px;
  padding: 8px 10px;
  width: 100%;
  resize: vertical;
`;

export const CardLabel = styled.div`
  margin-bottom: 8px;
`;

export const TextSecondary = styled.span`
  color: ${colors.grey};
  font-weight: 400;
`;

export const TaskList = styled.ul`
  display: flex;
  gap: 12px;
  flex-direction: column;
`;

export const Task = styled.li`
  align-items: center;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: row;
  gap: 12px;
  padding: 18px 16px;
`;

export const TaskCheckmark = styled.div`
  align-items: center;
  background: ${props => (props.checked ? '#298784' : '#496270')};
  color: ${props => (props.checked ? '#fff' : '#496270')};
  border-radius: 3px;
  display: flex;
  height: 20px;
  justify-content: center;
  opacity: 0.5;
  width: 20px;
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 16px;
  margin-top: 26px;
`;

export const RejectAction = styled.button`
  background: #f31414;
  border: none;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  min-width: 140px;
  padding: 10px 16px;
  text-align: center;

  &:hover {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  }

  &:disabled {
    background: #496270;
    cursor: default;
    opacity: 0.5;

    &:hover {
      box-shadow: none;
    }
  }
`;

export const ConfirmAction = styled.button`
  background: #298784;
  border: none;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  min-width: 140px;
  padding: 10px 16px;
  text-align: center;

  &:hover {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  }

  &:disabled {
    background: #496270;
    cursor: default;
    opacity: 0.5;

    &:hover {
      box-shadow: none;
    }
  }
`;

export const DetailsTable = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  gap: 12px;
  background: #fff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
  margin-top: 24px;
`;

export const DetailsTableRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
  width: 100%;
`;

export const DetailsTableSpacer = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
`;

export const ButtonGroupWrapper = styled.div`
  float: left;
  position: sticky;
  bottom: -20px;
  background: ${commonPalette.white};
  padding-right: 320px;
  margin-top: 10px;
`;

export const ExpiredDateWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledChip = styled.div`
  border-radius: 4px;
  background-color: #f9e6e6;
  border-color: #999999;
  border-width: 1px;
  border-style: solid;
  margin-right: 5px;
  text-overflow: elipsis;
  white-space: nowrap;
  align-self: center;
  padding: 2px 8px;
  color: #a72824;
`;

export const WrapperTextArea = styled.div`
  flex: 1;
  position: relative;
`;

export const CharactersInfo = styled.div`
  position: absolute;
  bottom: 5px;
  right: 10px;
  color: ${commonPalette.textSecondary};
  padding: 0 5px;
  background-color: white;
`;
