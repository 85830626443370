import {capitalize} from 'lodash';
import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {
  Card,
  CardLabel,
  CardLevel,
  CardLevelNum,
  CardRow,
  CardLevelContainer,
  CardTextarea,
  CloseButton,
  DrawerSubtitle,
  DrawerTitle,
  DrawerWrapper,
  Paragraph,
  ButtonGroupWrapper,
  Callout,
  WrapperTextArea,
  CharactersInfo
} from './styles';
import Icon from 'ui-components/Layout/Icon';
import AltIcon from 'ui-components/Icon';
import ButtonGroup from 'ui-components/ButtonGroup';
import AssessSkillDropdown from '../../components/AssessSkillDropdown';
import {inject, observer} from 'mobx-react';
import {graphql} from '@apollo/client/react/hoc/graphql';
import {AssessUserSkill} from 'api/skill-matrix/mutations';
import {assessUserSkillOptions} from 'api/skill-matrix/mutation-options';
import {SkillLevels} from 'api/skill-matrix/queries';
import mutationNames from 'api/skill-matrix/constants';
import notification from 'utils/notification-utils';
import messages from './messages';

import SkillCard from './SkillCard';
import SkillHistory from './SkillHistory';
import {skillLevelsOptions} from 'api/skill-matrix/query-options';
import AssessedDetails from './AssessedDetails';
import CurrentLevelCard from './CurrentLevelCard';

@inject('store')
@graphql(AssessUserSkill, assessUserSkillOptions)
@graphql(SkillLevels, skillLevelsOptions)
@observer
class AssessSkillDrawer extends Component {
  constructor() {
    super();

    this.state = {
      feedback: '',
      updatedLevel: null
    };
  }

  runMutation = async (updatedLevelId, skillLevels) => {
    const {
      close,
      skill,
      userId,
      intl: {formatMessage}
    } = this.props;
    const updatedLevelPosition = skillLevels.find(({id}) => id === updatedLevelId)?.position;

    const approved = Boolean(updatedLevelPosition >= skill.requiredLevel.position);
    const approveSkillMutation = this.props[mutationNames.ASSESS_USER_SKILL];

    const response = await approveSkillMutation({
      approved,
      userId,
      skillId: skill.id,
      feedback: this.state.feedback,
      updatedLevelId
    });

    if (!response?.data?.assessUserSkill) {
      // Unknown error
      notification.error(formatMessage(approved ? messages.approveError : messages.updateError));
      return;
    }

    const {assessUserSkill} = response.data;

    if (assessUserSkill.result === true) {
      notification.success(formatMessage(approved ? messages.approveSuccess : messages.updateSuccess));
      close();
    } else {
      notification.error(formatMessage(approved ? messages.approveError : messages.updateError));
    }
  };

  storeFeedback = event => {
    const {value: feedback} = event.target;
    this.setState(previousState => ({
      ...previousState,
      feedback
    }));
  };

  showReAssessDetails = status => status === 'APPROVED' || status === 'EXPIRED';

  getDrawerHeaderWithSkillDetails = () => {
    const {
      skill,
      userName,
      intl: {formatMessage}
    } = this.props;

    return this.showReAssessDetails(skill?.status) ? (
      <>
        <DrawerTitle>{formatMessage(messages.skillDetails)}</DrawerTitle>
        <Paragraph>{formatMessage(messages.skillDetailsDescription, {name: userName})}</Paragraph>

        <AssessedDetails skill={skill}></AssessedDetails>
      </>
    ) : (
      <>
        <DrawerTitle>{formatMessage(messages.assessSkill)}</DrawerTitle>
        <Paragraph>{formatMessage(messages.assessSkillDescription)}</Paragraph>

        <SkillCard skill={skill} />
      </>
    );
  };

  isLowerLevel = (selectedLevelId, currentLevel, skillLevels) => {
    if (!currentLevel || !selectedLevelId) return false;

    const selectedLevel = skillLevels.find(({id}) => id === selectedLevelId);
    return selectedLevel.position < currentLevel.position;
  };

  getReadyOnlyAssessmentInfo = (currentSkill, skillLevels) => {
    const {
      intl: {formatMessage}
    } = this.props;

    return (
      <>
        <DrawerSubtitle>{formatMessage(messages.assessment)}</DrawerSubtitle>

        <Card>
          <CardLevelContainer>
            <CurrentLevelCard
              position={currentSkill.currentLevel?.position}
              label={currentSkill.currentLevel?.name}
              skillLevels={skillLevels}
            />
            <CardRow>
              <CardLabel>{formatMessage(messages.selfAssessedLevel)}</CardLabel>
              {currentSkill.selfAssessmentLevel ? (
                <CardLevel>
                  <CardLevelNum>{currentSkill.selfAssessmentLevel?.position}</CardLevelNum>
                  {capitalize(currentSkill.selfAssessmentLevel?.name)}
                </CardLevel>
              ) : (
                <Paragraph>{formatMessage(messages.notProvided)}</Paragraph>
              )}
            </CardRow>
          </CardLevelContainer>
        </Card>
      </>
    );
  };

  render() {
    const {
      close,
      skill,
      skillLevelsQuery,
      intl: {formatMessage},
      store
    } = this.props;
    const {isIPA} = store.auth.user;
    const maxLength = 350;

    if (!skill || skillLevelsQuery.loading) return null;

    // if skill not started, assign first level as current level
    const currentSkill = {...skill, currentLevel: skill?.currentLevel};
    const requiredSkillName = capitalize(currentSkill?.requiredLevel?.name);
    const currentSkillPosition = currentSkill?.currentLevel?.position;

    const skillLevels = skillLevelsQuery?.skillLevels.map(skill => {
      return {
        position: skill.position,
        name: capitalize(skill.name),
        id: skill.id,
        required: requiredSkillName === capitalize(skill.name),
        currentLevel: skill.position === currentSkillPosition,
        description: skill.description
      };
    });

    const formattedSkillLevels = skillLevels?.map(
      level => `${level.position} - ${level.name}${level.description ? ' - ' + level.description : ''}`
    );

    const popoverContent = formattedSkillLevels || formatMessage(messages.unableToLoadSkillLevels);

    return (
      <DrawerWrapper>
        <CloseButton>
          <Icon name={'close'} size={16} onClick={() => close()} />
        </CloseButton>

        {this.getDrawerHeaderWithSkillDetails()}

        {/* IPA has read-only access */}
        {isIPA && this.getReadyOnlyAssessmentInfo(currentSkill, skillLevels)}
        {!isIPA && (
          <>
            {this.showReAssessDetails(currentSkill?.status) ? (
              <DrawerSubtitle>{formatMessage(messages.reAssessSkill)}</DrawerSubtitle>
            ) : (
              <DrawerSubtitle>{formatMessage(messages.yourAssessment)}</DrawerSubtitle>
            )}
            <Paragraph>{formatMessage(messages.yourAssessmentDescription)}</Paragraph>
            <Card>
              <CardLevelContainer>
                <CurrentLevelCard
                  position={currentSkill.currentLevel?.position}
                  label={currentSkill.currentLevel?.name}
                  skillLevels={skillLevels}
                />
                {currentSkill.selfAssessmentLevel && (
                  <CardRow>
                    <CardLabel>{formatMessage(messages.selfAssessedLevel)}</CardLabel>
                    <CardLevel>
                      <CardLevelNum>{currentSkill.selfAssessmentLevel?.position}</CardLevelNum>
                      {capitalize(currentSkill.selfAssessmentLevel?.name)}
                    </CardLevel>
                  </CardRow>
                )}
              </CardLevelContainer>

              <CardLabel>
                {formatMessage(messages.setLevel)}
                <AltIcon
                  iconId="info"
                  popoverContent={popoverContent}
                  popoverTitle="Level details:"
                  showDash={false}
                  style={{marginLeft: '4px'}}
                />
              </CardLabel>
              <AssessSkillDropdown
                placeholder={formatMessage(messages.selectLevel)}
                options={skillLevels}
                defaultValue={formatMessage(messages.selectLevel)}
                selectedValue={this.state.updatedLevel}
                style={{marginBottom: '10px', height: '46px'}}
                onChange={selectedValue => {
                  this.setState(previousState => ({
                    ...previousState,
                    updatedLevel: selectedValue
                  }));
                }}
              ></AssessSkillDropdown>

              {/* only display callout is selected level is lower that the current skill level  */}
              {this.isLowerLevel(this.state.updatedLevel, currentSkill.currentLevel, skillLevels) && (
                <Callout>
                  <div>
                    <Icon name={'info'} size={32} style={{position: 'relative', left: '-3px', color: '#0058BA'}} />
                  </div>
                  <div>{formatMessage(messages.lowerLevelInfo)}</div>
                </Callout>
              )}

              <CardLabel>{formatMessage(messages.feedback)}</CardLabel>
              <WrapperTextArea>
                <CardTextarea
                  onChange={event => this.storeFeedback(event)}
                  placeholder={formatMessage(messages.feedbackPlaceholder)}
                  maxLength={maxLength}
                />
                <CharactersInfo>
                  {this.state.feedback.length}/{maxLength}
                </CharactersInfo>
              </WrapperTextArea>
            </Card>
          </>
        )}

        <SkillHistory skill={currentSkill} />
        <ButtonGroupWrapper>
          {/* IPA has read-only access */}
          {isIPA ? (
            <ButtonGroup
              primary={{
                label: formatMessage(messages.ok),
                onClick: () => close()
              }}
              style={{marginTop: '15px', marginBottom: '15px'}}
            />
          ) : (
            <ButtonGroup
              primary={{
                label: this.showReAssessDetails(skill?.status)
                  ? formatMessage(messages.reAssess)
                  : formatMessage(messages.assess),
                onClick: () => this.runMutation(this.state.updatedLevel, skillLevels)
              }}
              secondary={{
                label: formatMessage(messages.cancel),
                onClick: () => close()
              }}
              style={{marginTop: '15px', marginBottom: '15px'}}
            />
          )}
        </ButtonGroupWrapper>
      </DrawerWrapper>
    );
  }
}

export default injectIntl(AssessSkillDrawer);
