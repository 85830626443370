import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';
import {DOMAIN_LOGIN_OPTIONS} from 'shared/enums';

//styled-components
import Select from 'ui-components/Select';

//messages
import messages from './messages';
import {camelCase} from 'lodash';

@inject('store')
@observer
@injectIntl
export default class ContactMethodPicker extends Component {
  render() {
    const {
      className,
      dataCy,
      defaultValue,
      disabled,
      field,
      form,
      intl: {formatMessage},
      label,
      onChange,
      style,
      selectedValue
    } = this.props;

    const methods = [DOMAIN_LOGIN_OPTIONS.EMAIL, DOMAIN_LOGIN_OPTIONS.PHONE_NUMBER].map(method => {
      return {name: formatMessage(messages[camelCase(method)]), id: method};
    });

    return (
      <Select
        dataCy={dataCy}
        style={style}
        selectedValue={selectedValue}
        allowClear={false}
        className={className}
        defaultValue={defaultValue}
        disabled={disabled}
        field={field}
        form={form}
        label={label}
        options={methods}
        onChange={onChange}
        placeholder={formatMessage(messages.placeholder)}
      />
    );
  }
}
